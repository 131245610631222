.featured-product {
  margin-bottom: 5rem;
}

.featured-product .product__media-list {
  width: 100%;
  margin: 0;
  padding-bottom: 0;
}

.featured-product .product__media-item {
  padding-left: 0;
  width: 100%;
}

.featured-product .product__media-item:not(:first-child) {
  display: none;
}

.featured-product-section .background-secondary {
  padding-top: 5rem;
  padding-bottom: 0.1rem;
}

.background-secondary .featured-product {
  background: rgb(var(--color-background));
  padding: 2.5rem;
}

.product__view-details {
  display: block;
  text-decoration: none;
}

.product__view-details:hover {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.product__view-details .icon {
  width: 1.2rem;
  margin-left: 1.2rem;
  flex-shrink: 0;
}

.featured-product .share-button:nth-last-child(2) {
  display: inline-flex;
}

.share-button + .product__view-details {
  display: inline-flex;
  float: right;
}

.share-button + .product__view-details::after {
  content: "";
  clear: both;
  display: table;
}

@media screen and (min-width: 750px) {
  .featured-product .product__media-item {
    padding-bottom: 0;
  }

  .featured-product-section .background-secondary {
    padding-top: 7rem;
    padding-bottom: 2rem;
  }

  .background-secondary .featured-product {
    padding: 5rem;
  }
}

@media screen and (min-width: 990px) {
  .background-secondary .featured-product:not(.product--no-media) > .product__info-wrapper {
    padding: 0 0 0 5rem;
  }

  .featured-product:not(.product--no-media) > .product__info-wrapper {
    padding: 0 7rem;
  }

  .background-secondary .featured-product {
    background: rgb(var(--color-background));
    padding: 6rem 7rem;
  }
}
